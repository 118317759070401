/**
 *  Related Section Style
 **/

section.site-section.promotions-section {
  .lead {
    color: $color-promotions-text;
    font-weight: bold;
  }
}
