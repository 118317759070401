/**
 *  Global Style
 **/

body {
  color: $color-light-primary;
  background-color: $color-light-bg;

  // Typography
  font: {
    family: Verdana, Arial, sans-serif;
    weight: normal;
  }
  //letter-spacing: 1px;
  word-spacing: -1px;
  -webkit-font-smoothing: antialiased;
}

// Paragraphs
p {
  line-height: 1.5;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}

// Horizontal Lines
hr {
  clear: both;
  border: none;
  width: 100%;
  height: 2px;
  background-color: $color-light-secondary;

  &.separator {
    width: 60%;
  }

  &.small {
    width: 3em;
    margin: 1.5em auto;
    background-color: $color-light-primary;
  }

  &.medium {
    width: 70px;
    background-color: $color-light-primary;
  }

  &.normal {
    position: relative;
    border: none;
    width: 100px;
    height: 4px;
    background-color: $color-light-secondary;

    &:after {
      display: block;
      position: absolute;
      content: "";
      width: 50%;
      height: 4px;
      background: $color-light-primary;
      top: 0;
      right: 0;
    }
  }

}

// Links
a {
  color: $color-light-link;
  text-decoration: none;
  transition: all $transition-links $transition-links-type;

  &:hover,
  &:active {
    color: darken($color-light-link, 10%);
    font-weight: 500;
  }
}

.bg-transparent-white {
  background: transparentize($color-white, 0.5);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

.bg-transparent-black {
  background: transparentize($color-black, 0.4);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
}

// Images
img {
  position: relative;
  max-width: 100%;
}

iframe {
  border: 0;
  width: 100%;
  max-width: 100%;
}

// Preformatted Monospaced
p.premono {
  font-family: monospace;
  white-space: pre-wrap;       // css-3 
  white-space: -moz-pre-wrap;  // Mozilla, since 1999
  white-space: -pre-wrap;      // Opera 4-6
  white-space: -o-pre-wrap;    // Opera 7
  word-wrap: break-word;       // Internet Explorer 5.5+
  &:first-line {
    line-height: 0px;
  }
}
